export const INITIALIZE_USER = "INITIALIZE_USER";
export const RESET_USER = "RESET_USER";
export const SET_USER_FIREBASE_DATA = "SET_USER_FIREBASE_DATA";
export const SET_AUTHENTICATING_USER = "SET_AUTHENTICATING_USER";
export const SET_VERIFICATION_STATUS = "SET_VERIFICATION_STATUS";
export const SET_USER_PROFILE_PICTURE_URL = "SET_USER_PROFILE_PICTURE_URL";
export const SET_USER_PROFILE_PICTURE_IS_LOADING =
  "SET_USER_PROFILE_PICTURE_IS_LOADING";
export const SET_USER_PROFILE = "SET_USER_PROFILE";
export const SET_USER_GOALS = "SET_USER_GOALS";
export const SET_USER_PROGRESS = "SET_USER_PROGRESS";
export const ADD_USER_PROGRESS_ITEM = "ADD_USER_PROGRESS_ITEM";
export const REMOVE_USER_PROGRESS_ITEM = "REMOVE_USER_PROGRESS_ITEM";
export const EDIT_USER_PROGRESS_ITEM = "EDIT_USER_PROGRESS_ITEM";
export const SET_USER_FOODS = 'SET_USER_FOODS'
export const ADD_USER_FOOD = 'ADD_USER_FOOD'
export const REMOVE_USER_FOOD = 'REMOVE_USER_FOOD'
